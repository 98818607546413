* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.row {
  flex-direction: row;
  justify-content: center;
}
.homeP {
  margin: 60px;
}
.inputP {
  margin-bottom: 30px;
  padding: 10px;
}
